import React from "react"
import { IntlProvider } from "react-intl"

import messages_en from "../../assets/data/locales/en.json"
import messages_ar from "../../assets/data/locales/ar.json"

const menu_messages = {
  en: messages_en,
  sa: messages_ar
}

const Context = React.createContext()

class IntlProviderWrapper extends React.Component {
  state = {
    locale: localStorage.getItem("locale") ?? "sa",
    messages: menu_messages[localStorage.getItem("locale") ?? "sa"]
  }

  render() {
    const { children } = this.props
    const { locale, messages } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          switchLanguage: language => {
            localStorage.setItem("locale", language)
            this.setState({
              locale: language,
              messages: menu_messages[language]
            })
          }
        }}
      >
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="sa"
        >
          {children}
        </IntlProvider>
      </Context.Provider>
    )
  }
}

export { IntlProviderWrapper, Context as IntlContext }
