// ** Initial State
const initialState = {
  products: [],
  combos: [],
  orderType: '',
  coordinates: {lat: 0, lng: 0},
  branch: null,
  fullAddress: null,
  subtotal: 0,
  chargeAmount: 0,
  chargesTaxes: 0,
  taxesPrice: 0,
  foodicsTaxes: 0,
  foodicsCharges: 0,
  foodicsTotal: 0,
  branchTaxes: 0,
  modifiersTaxes: 0,
  integrated: false,
  deliveryBranches : [],
  pickupBranches: [],
  deliveryZone: {},
  coupon: '',
  totalDiscount: null,
  paymentData: null
}
  
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-PRODUCT': {
      const products = [...state.products]
      products.push({...action.payload})
      return {...state, products: [...products] }
    } 
    case 'SET-PRODUCT-QUANTITY': {
      const products = [...state.products]  
      products[action.payload.index].productQuantity = action.payload.value
      return { ...state, products: [...products] }
    } 
    case 'REMOVE-PRODUCT' : {
      const products = [...state.products]
      products.splice(action.payload, 1)
      return { ...state, products: [...products] }
    }
    case 'SET-COUPON' : {
      return { ...state, coupon: action.payload }
    }
    case 'SET-COMBO': {
      const combos = [...state.combos]
      combos.push({...action.payload})
      return {...state, combos: [...combos] }
    } 
    case 'SET-COMBO-QUANTITY': {
      const combos = [...state.combos]  
      combos[action.payload.index].comboQuantity = action.payload.value
      return { ...state, combos: [...combos] }
    } 
    case 'REMOVE-COMBO' : {
      const combos = [...state.combos]
      combos.splice(action.payload, 1)
      return { ...state, combos: [...combos] }
    }
    case 'SET-ORDER-TYPE' : {
      return { ...state, orderType: action.payload }
    }
    case 'SET-COORDINATES' : {
      return { ...state, coordinates: action.payload }
    }
    case 'SET-DELIVERY-ZONE' : {
      return { ...state, deliveryZone: action.payload }
    }
    case 'SET-FULL-ADDRESS' : {
      return { ...state, fullAddress: action.payload }
    }
    case 'SET-BRANCH-ID' : {
      return { ...state, branch: action.payload }
    }
    case 'SET-PAYMENT-DATA' : {
      return { ...state, paymentData: action?.payload}
    }
    case 'SET-SUBTOTAL' : {
      return {...state, subtotal: action.payload}
    }
    case 'SET-CHARGE' : {
      return {...state, chargeAmount: action.payload}
    }
    case 'SET-CHARGE-TAX' : {
      return {...state, chargesTaxes: action.payload}
    }
    case 'FOODICS-TOTAL' : {
      return {...state, foodicsTotal: action.payload}
    }
    case 'FOODICS-TAXES' : {
      return {...state, foodicsTaxes: action.payload}
    }
    case 'FOODICS-CHARGES' : {
      return {...state, foodicsCharges: action.payload}
    }
    case 'SET-TAXES' : {
      return {...state, taxesPrice: action.payload}
    }
    case 'SET-BRANCH-TAXES': {
      return {...state, branchTaxes: action.payload}
    }
    case 'SET-MODIFIERS-TAXES': {
      return {...state, modifiersTaxes: action.payload}
    }
    case 'INTEGRATED': {
      return {...state, integrated: action.payload}
    }
    case 'SET-DELIVERY-BRANCHES' : {
      return {...state, deliveryBranches: action.payload}
    }
    case 'SET-TOTAL-DISCOUNT' : {
      return {...state, totalDiscount: action.payload}
    }
    case 'SET-PICKUP-BRANCHES' : {
      return {...state, pickupBranches: action.payload}
    }
    case 'SET-CART' : {
      return {...action.payload}
    }
    case 'RESET-CART' : {
      return initialState
    }
    default:
      return state
  }
}
  
export default cartReducer