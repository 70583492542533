/*eslint-disable */
import React, { useEffect, useState, useContext } from "react"
import { Button } from 'reactstrap';
import { getPopUp } from "../../services/Home/service"
import { X } from 'react-feather'
import { PopUpContext } from "../../utility/context/popUpContext";
import { useSelector } from "react-redux";
import { handleRedirectUrl } from "../../utility/Utils";
import './popUp.css'

const PopUp = () => {
    const [popUp, setPopUp] = useState(null)
    const [active, setActive] = useState(false)
    const context = useContext(PopUpContext)
    const utm_source = useSelector(state => state?.navbar?.utm_source)

    useEffect(() => {
        if (context?.state?.activePopUp)
            fetchPopUp()
    }, [context?.state?.activePopUp])

    const fetchPopUp = () => {
        getPopUp(context?.state?.activePopUp)
            .then(res => {
                setPopUp(res?.data)
                setTimeout(() => {
                    setActive(true)
                }, 100);
            })
            .catch(err => console.log(err))
    }

    if (!context?.state?.show) {
        return null
    }

    return <div className={`popUp ${active ? "d-flex" : "d-none"}`} style={{ overflow: "hidden" }}>
        <div className="speed">
            <div className="popUp-container" style={{ backgroundImage: `url(${popUp?.background_image})` }} >
                <div
                    className="cursor-pointer background-blur rounded-circle justify-content-center d-flex align-items-center close"
                    onClick={() => context.onVisibilityChange({ show: false, activePopup: null })}
                >
                    <X className='icon-color' size={20} />
                </div>
                <div className={`popUp-content ${popUp?.popup_text && 'background-blur mx-2'}`}>
                    {popUp?.popup_text && <h2 className="mb-2 card-text">{popUp?.popup_text}</h2>}
                    <Button color="primary" onClick={() => handleRedirectUrl(popUp?.redirect_url, utm_source)}>
                        {popUp?.button_text}
                    </Button>
                </div>
            </div>
        </div>

    </div>

}

export default PopUp