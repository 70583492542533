// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme?.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// Color Contrast Exam

export function luminance(r, g, b) {

  const a = [r, g, b].map(function (v) {
      v /= 255
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })

  return (a[0] * 0.2126) + (a[1] * 0.7152) + (a[2] * 0.0722)
}
// get item from localstorage
export const getWithExpiry = (key, branch) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
      return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (item?.expire && (now.getTime() > item.expire)) {
      localStorage.removeItem(key)
      return null
  }
  if (Number(item.branch) !== Number(branch)) {
    return null
  }
  return item.value
}
//set item to local storage with expire after 2hrs
export const setWithExpiry = (value, key, time) => {
  const now = new Date()
  const expire = time ?  {expiry: now.getTime() + time} : {}
  const item = {
      value: value.id,
      branch: value.branch,
      ...expire
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const orderTypes = [
  {label: 'dine-in', value: 1},
  {label: 'pickup', value: 2},
  {label: 'delivery', value: 3}
]

export function validateEmail(email) {
  if (!email) return true
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const getSrc = (src) => {
  const baseURL = 'https://staging.florinz.com'
  if (src?.includes("http")) {
      return src
  } else {
      return `${baseURL}${src}`
  }
}

import { matchPath } from "react-router"
import { Routes } from '../router/routes'

//check if url is internal
export const handleRedirectUrl = (redirect_url, utm_source) => {
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/
  const matches = redirect_url.match(pathNameRegex)
  let matchRoute = false
  if (matches?.length > 0) {
    Routes?.find(route => {
      if (matchPath(matches[1], {
          path: route.path,
          exact: true,
          strict: true
      })?.isExact) {
        matchRoute = true
      }
    })
  }
  if (matchRoute && utm_source) {
    window.open(`${redirect_url}/?utm_source=${utm_source}`, '_self')
  } else window.open(redirect_url, '_self')
}
