// ** Initial State
const initialState = {
  establishment: {},
  subscriptions: {},
  suggestions: [],
  bookmarks: [],
  query: '',
  paymentChoices: [],
  utm_source: '',
  punchCards: [],
  login: {},
  notifications: []
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-ESTABLISHMENT':
      return { ...state, establishment: action.payload }
    case 'SET-BRANCH':
      return {
        ...state,
        establishment: { ...state.establishment, branch: action.payload }
      }
    case 'SET-SUBSCRIPTIONS':
      return { ...state, subscriptions: action.payload }
    case 'HANDLE_SEARCH_QUERY':
      return { ...state, query: action.val }
    case 'SET-PAYMENT-CHOICES':
      return { ...state, paymentChoices: action.payload }
    case 'GET_BOOKMARKS':
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case 'SET-UTM':
      return { ...state, utm_source: action.payload }
    case 'SET-LOGIN-CUSTOMER':
      return { ...state, login: action.payload }
    case 'SET-PUNCH-CARDS': 
    return {...state, punchCards: action.payload}
    case 'SET-NOTIFICATIONS':
      return { ...state, notifications: action.payload }
    case 'UPDATE_BOOKMARKED':
      let objectToUpdate

      // ** find & update object
      state.suggestions.find((item) => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex((x) => x.id === action.id)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }

      return { ...state }
    default:
      return state
  }
}

export default navbarReducer
