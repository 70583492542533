import React from "react"
import messages_en from "../../assets/data/locales/en.json"
import messages_ar from "../../assets/data/locales/ar.json"

const menu_messages = {
  en: messages_en,
  sa: messages_ar
}

const Context = React.createContext()

class CategoryProviderWrapper extends React.Component {
  state = {
    active: 0,
    scrollOffset: 0
  }

  render() {
    const { children } = this.props
    const { active } = this.state
    return (
      <Context.Provider
        value={{
          state: this.state,
          onCategoryChange: category => {
            this.setState({
              active: category
            })
          },
          onScrollChange: scroll => {
            this.setState({
              scrollOffset: scroll
            })
          }
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}

export { CategoryProviderWrapper, Context as CategoryContext }
