import request from '../request'


export async function checkQR(params) {
    return request({
        method: 'POST',
        url: `/restaurant/branch/qr-check/`,
        data: params
    })
}

export async function establishmentData(uuid, utm_source) {
    return request({
        method: 'GET',
        url: `restaurant/qr/${uuid}/data/${utm_source ? `?utm_source=${utm_source}` : ""}`
    })
}

export async function getMainBranch() {
    return request({
        method: 'GET',
        url: `restaurant/main-branch/`
    })
}

export async function getPaymentTypesChoices(id) {
    return request({
        method: 'GET',
        url: `menu/qr/order/payment/choices/${id}/`
    })
}

export async function getPopUp(id) {
    return request({
        method: 'get',
        url: `/restaurant/popup/${id}`
    })
}
export async function evaluationPrizes(params) {
    return request({
        method: 'POST',
        url: `/evaluation/available-prizes/list/`,
        data: params
    })
}

export async function referralPrizes(params) {
    return request({
        method: 'POST',
        url: `/evaluation/referral/check-prize/`,
        data: params
    })
}

export async function getAllBranches(id) {
    return request({
        method: 'GET',
        url: `restaurant/branch/list-qr/${id}/`
    })
}

export async function getDeliveryZones(id) {
    return request({
        method: 'GET',
        url: `menu/delivery-zone/list/${id}`
    })
}

export async function getSlider(id) {
    return request({
        method: 'GET',
        url: `restaurant/slider/${id}`
    })
}